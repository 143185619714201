import { uniqueId } from "lodash";
import React, { useState } from "react";
import { FormGroup, Input, Tooltip } from "reactstrap";

function InputControl({ name, label, error, id = uniqueId(`${name}-`), ...rest }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const errorClass = error ? { borderColor: "#ff576c" } : {};
  const { tooltip } = rest;
  const tooltipElement = (
    <span>
      <i className="fa fa-question-circle" id={name} />
      <Tooltip placement="top" target={name} isOpen={tooltipOpen} toggle={toggle}>
        {tooltip}
      </Tooltip>
    </span>
  );
  return (
    <FormGroup>
      {label && (
        <label htmlFor={id}>
          {label} {tooltip && tooltipElement}
        </label>
      )}
      <Input
        {...rest}
        name={name}
        id={id}
        placeholder={label}
        style={errorClass}
        onWheel={(event) => {
          if (event.target.type === "number") document.activeElement.blur();
        }}
      />
      {error && <div style={{ color: "#ff576c", fontSize: "12px" }}>{error}</div>}
    </FormGroup>
  );
}

export default InputControl;
