import React from "react";
import { Button } from "reactstrap";
import { deleteSubChannel, saveSubChannel } from "../../services/subChannelService";
import StatusIndicator from "../common/StatusIndicator/StatusIndicator";
import PolygonsTable from "../channels/PolygonsTable";
import "../channels/Channel.scss";
import BaseForm from "../common/BaseForm";
import SubChannel from "../models/SubChannelModel";
import FieldBuilder from "../shared/utils/FieldBuilder";
import ResponsiveRow from "./base/ResponsiveRow";

class SubChannelForm extends BaseForm {
  state = {
    data: {},
    errors: {},
    status: "started",
    viewAreaUpdated: false,
  };
  subChannel = new SubChannel();
  schema = this.subChannel.getSchema();

  componentDidMount() {
    const { subChannel } = this.props;
    let data = null;
    if (subChannel) {
      data = subChannel;
    } else {
      data = this.subChannel.getDefaultData();
      data.channel = this.props.channelId;
    }
    this.setState({ data });
  }

  delete = async () => {
    let status = "deleting";
    this.setState({ status });
    try {
      const res = await deleteSubChannel(this.state.data.id);
      if (res.status === 204) {
        this.props.removeSubChannel(this.state.data.id);
      }
    } catch (exception) {
      if (exception.response) {
        status = "Failed to delete!";
        this.validateResponse(exception.response.data);
      } else {
        console.log(exception);
        status = "unknown";
      }
      this.setState({ status });
    }
  };

  handleOriginalResolutionSet = (resolution) => {
    const { data } = this.state;
    data.resolution = resolution;
    this.setState({ data });
  };

  handleViewAreaChange = (viewArea) => {
    const { data } = this.state;
    data.view_area = viewArea;
    this.setState({ data, viewAreaUpdated: true });
  };

  save = async () => {
    let status = "saving";
    this.setState({ status });
    try {
      const { data: subChannel, status: resCode } = await saveSubChannel(this.state.data);
      status = resCode < 300 ? "success" : "Could not save subchannel";
      this.setState({ status });
      if (status === "success") {
        this.props.updateSubChannelList(subChannel, this.props.subChannelIndex);
        return;
      }
      this.setState({ status });
    } catch (exception) {
      if (exception.response) {
        status = "Invalid data provided! ";
        if (exception.response.data.non_field_errors) {
          status += exception.response.data.non_field_errors;
        }
        this.validateResponse(exception.response.data);
      } else {
        console.log(exception);
        status = "unknown";
      }
      console.log(exception.response);
      this.setState({ status });
    }
  };

  render() {
    const { data, viewAreaUpdated, status } = this.state;
    const { className, deleteMode } = this.props;
    let statusType = null;
    let message = "";
    switch (status) {
      case "edited":
        statusType = null;
        message = null;
        break;
      case "started":
        statusType = null;
        message = null;
        break;
      case "saving":
        statusType = "progress";
        message = "saving...";
        break;
      case "success":
        statusType = "success";
        message = "saved!";
        break;
      default:
        statusType = "failed";
        message = status;
    }

    const statusIndicator = (
      <div className="float-right m-2">
        <StatusIndicator status={statusType} message={message} />
      </div>
    );
    return (
      <React.Fragment>
        <tr className={className}>
          <td>{this.renderInput("sub_channel_number", "Sub channel number")}</td>
          <td>{this.renderInput("channel_name", "Sub channel name")}</td>
          <td>
            {this.renderInput("favor_factor", "Favor factor", "number", {
              step: 0.01,
            })}
          </td>
          <td>No points</td>
          <td>{this.renderInput("anchor", "Anchor")}</td>
          <td>{this.renderToggleSwitch("is_active", "")}</td>
          {!deleteMode && (
            <td className="text-center">
              <Button className="float-right btn-success m-2" onClick={this.save}>
                <i className="fas fa-check" />
              </Button>
              <Button className="float-right btn-secondary m-2" onClick={this.props.toggleEditMode}>
                <i className="fas fa-times" />
              </Button>
              <ResponsiveRow
                md="4"
                fields={[
                  new FieldBuilder()
                    .setId("view_area")
                    .setName("view_area")
                    .setComponent(
                      <PolygonsTable
                        inputViewArea={data.view_area}
                        inputOriginalresolution={data.resolution || [[[]]]}
                        onPolygonsUpdate={this.handleViewAreaChange}
                        onOriginalResolutionSet={this.handleOriginalResolutionSet}
                        viewAreaUpdated={viewAreaUpdated}
                      />
                    )
                    .build(),
                ]}
                bottomSeparator={false}
              />
              {this.subChannel.id && (
                <Button className="float-right btn-danger m-2" onClick={this.props.toggleDeleteMode}>
                  <i className="fas fa-trash-alt" />
                </Button>
              )}
            </td>
          )}
          {deleteMode && (
            <td className="text-center">
              <Button className="float-right btn-danger m-2" onClick={this.delete}>
                <i className="fas fa-trash-alt mr-2" />
                Delete
              </Button>
              <Button className="float-right btn-secondary m-2" onClick={this.props.toggleDeleteMode}>
                <i className="fas fa-times mr-2" />
                Cancel
              </Button>
            </td>
          )}
        </tr>
        <tr className="status-container">
          <td colSpan={8}>{status && statusIndicator}</td>
        </tr>
      </React.Fragment>
    );
  }
}

export default SubChannelForm;
