import React, { Component } from "react";
import { Table, Form, Row, Col, Button } from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";

import "../StoreDetailView.scss";
import ChannelScreenshot from "./ChannelScreenshot";
import Gallery from "../gallery/Gallery";
import { trigggerBulkScreenshots, fetchBulkScreenshots } from "../../services/bulkScreenshotsService";
import downloadFile from "../../utils/downloadUtils";

class ChannelsTableScreenshot extends Component {
  state = {
    loadScan: null,
    loadZip: null,
    screenshots: [],
    screenshotsPolygons: [],
    formId: 0,
    channels: [],
  };

  componentDidMount() {
    let { channels } = this.props;
    channels = channels.sort(function (a, b) {
      return a.channel_number - b.channel_number;
    });
    this.setState({ channels });
  }

  setScreenshotState(state, destination) {
    if (destination === "zip") {
      this.setState({ loadZip: state });
    }
    if (destination === "gallery") {
      this.setState({ loadScan: state });
    }
  }

  createChannels = (channelsInput, videoRecorder) => {
    const channelsOutput = [];
    const ip = videoRecorder.device_ip || "";
    const suffix = videoRecorder.rtsp_url_suffix || "";
    const rangeCurlyBraces = "{}";
    const rangeCurlyBracesOpen = "{";
    const rangeCurlyBracesClose = "}";
    if (!ip.includes(rangeCurlyBraces) && !suffix.includes(rangeCurlyBraces)) {
      channelsInput.forEach((channel) => {
        channelsOutput.push({
          ...videoRecorder,
          channel_number: channel.channel_number,
          protocol: channel.protocol,
          dewarp: channel.sub_channels.length > 0,
        });
      });
    }
    if (ip.includes(rangeCurlyBraces)) {
      const rootDeviceIp = ip.split(rangeCurlyBracesOpen)[0];
      channelsInput.forEach((channel) => {
        channelsOutput.push({
          ...videoRecorder,
          channel_number: channel.channel_number,
          device_ip: rootDeviceIp + channel.channel_number,
          protocol: channel.protocol,
          dewarp: channel.sub_channels.length > 0,
        });
      });
    }
    if (suffix.includes(rangeCurlyBraces)) {
      const beginRtsp = suffix.split(rangeCurlyBracesOpen)[0];
      const endRtsp = suffix.split(rangeCurlyBracesClose)[1];
      channelsInput.forEach((channel) => {
        channelsOutput.push({
          ...videoRecorder,
          channel_number: channel.channel_number,
          rtsp_url_suffix: beginRtsp + channel.channel_number + endRtsp,
          protocol: channel.protocol,
          dewarp: channel.sub_channels.length > 0,
        });
      });
    }
    return channelsOutput;
  };

  showGallery = (channels, screenshots, screenshotsPolygons) => {
    const { formId } = this.state;
    const { showScreenshot, showGallery } = this.props;
    this.setState({ screenshots, screenshotsPolygons, formId: formId + 1 });
    showScreenshot(false);
    showGallery(true);
  };

  handleScreenshotGeneration = async (screenshotsDestination) => {
    const { showGallery, videoRecorder, serverIp } = this.props;
    let { channels } = this.state;
    if (screenshotsDestination === "gallery") {
      showGallery(false);
    }
    const channelNumbers = channels.map((channel) => channel.channel_number);
    const channelRanges = channelNumbers.join(",");
    channels = this.createChannels(channels, videoRecorder);
    if (channels.length === 0) {
      return;
    }
    this.setScreenshotState("load", screenshotsDestination);
    const storeLocation = videoRecorder.store;
    const videoRecorders = [];
    channels.forEach((channel) => {
      const dvrData = {
        channels: [
          {
            channel_number: channel.channel_number,
            protocol: channel.protocol,
            dewarp: channel.dewarp,
          },
        ],
        device_ip: channel.device_ip,
        device_rtsp_port: channel.device_rtsp_port,
        username: channel.username,
        password: channel.password,
        type: { name: videoRecorder.video_recorder_type_name },
        rtsp_url_suffix: channel.rtsp_url_suffix,
        sub_type: channel.sub_type,
      };
      videoRecorders.push(dvrData);
    });
    const videoRecorderId = videoRecorder.id;
    if (serverIp) {
      toast.info(screenshotsDestination === "zip" ? "Zip screenshots is running!" : "Scanning in progress!");
      await trigggerBulkScreenshots(
        serverIp,
        storeLocation,
        screenshotsDestination,
        videoRecorders,
        videoRecorderId,
        channelRanges
      )
        .then((triggerResponse) => {
          if (triggerResponse.data.status === "running") {
            fetchBulkScreenshots(
              serverIp,
              storeLocation,
              screenshotsDestination,
              videoRecorders,
              videoRecorderId,
              channelRanges
            )
              .then((fetchResponse) => {
                if (fetchResponse.data.status === "success") {
                  this.setScreenshotState("success", screenshotsDestination);
                  if (screenshotsDestination === "zip") {
                    downloadFile(fetchResponse.data.zip_file_url);
                  }
                  if (screenshotsDestination === "gallery") {
                    this.showGallery(channels, fetchResponse.data.screenshots, fetchResponse.data.screenshots_polygon);
                  }
                } else {
                  this.setScreenshotState("failed", screenshotsDestination);
                  toast.error(fetchResponse.data.message);
                }
              })
              .catch((ex) => {
                this.setScreenshotState("failed", screenshotsDestination);
                toast.error(ex.response.data.message);
              });
          } else if (triggerResponse.data.status === "success") {
            this.setScreenshotState("success", screenshotsDestination);
            if (screenshotsDestination === "zip") {
              downloadFile(triggerResponse.data.zip_file_url);
            }
            if (screenshotsDestination === "gallery") {
              this.showGallery(channels, triggerResponse.data.screenshots, triggerResponse.data.screenshots_polygon);
            }
          } else {
            this.setScreenshotState("failed", screenshotsDestination);
            toast.error(triggerResponse.data.message);
          }
        })
        .catch((ex) => {
          this.setScreenshotState("failed", screenshotsDestination);
          toast.error(ex.response.data);
        });
    }
  };

  render() {
    const { loadScan, loadZip, channels, screenshots, screenshotsPolygons, formId, serverIp } = this.state;
    const { isGalleryShown, videoRecorder, onClick } = this.props;
    return (
      <Form>
        <Table className="tablesorter" responsive>
          <thead className="text-primary">
            <tr>
              <th>Channel number</th>
              <th>Channel name</th>
              <th>Protocol</th>
              <th>DVR type</th>
              <th>DVR IP</th>
              <th>DVR RTSP port</th>
              <th>Sub Type</th>
              <th>Machine</th>
              <th>Screenshot</th>
              <th className="text-center" />
            </tr>
          </thead>
          <tbody>
            {channels &&
              channels.map((channel) => (
                <ChannelScreenshot
                  key={channel.channel_number}
                  channel={channel}
                  videoRecorder={videoRecorder}
                  onClick={onClick}
                />
              ))}
          </tbody>
        </Table>

        <Row>
          <Col className="pr-md-1 ml-auto">
            <Button
              className="float-right btn-success m-0 align-input"
              onClick={() => this.handleScreenshotGeneration("gallery")}
            >
              {loadScan === "load" && (
                <i style={{ display: "inline-block" }} className="mr-2">
                  <ThreeDots height="15" width="20" radius="5" color="#ffffff" ariaLabel="three-dots-loading" visible />
                </i>
              )}
              {loadScan !== "load" && <i className="fas fa-binoculars mr-2" />}
              Scan
            </Button>
          </Col>
          <Col className="float-right col-sm-auto px-0">
            <Button
              className="float-right btn-info ml-0 align-input"
              onClick={() => this.handleScreenshotGeneration("zip")}
            >
              {loadZip === "load" && (
                <i style={{ display: "inline-block" }} className="mr-2">
                  <ThreeDots height="15" width="20" radius="5" color="#ffffff" ariaLabel="three-dots-loading" visible />
                </i>
              )}
              {loadZip !== "load" && <i className="fas fa-file-archive mr-2" />}
              Download ZIP
            </Button>
          </Col>
        </Row>
        {isGalleryShown && (
          <Gallery
            screenshots={screenshots}
            screenshotsPolygons={screenshotsPolygons}
            formId={formId}
            serverIp={serverIp}
            channels={channels}
          />
        )}
      </Form>
    );
  }
}

export default ChannelsTableScreenshot;
