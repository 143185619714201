import React, { Component } from "react";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { fetchScreenshot, triggerScreenshot } from "../../services/scanVideoRecorderService";
import { ReactComponent as Fail } from "./failed.svg";
import { ReactComponent as Load } from "./loader.svg";

class Screenshot extends Component {
  state = {
    screenshotState: "",
    dataUri: "",
    dataUri1: "",
    dataUri2: "",
    dataUri3: "",
    dataUriPolygons: "",
    dataUriPolygonsSub1: "",
    dataUriPolygonsSub2: "",
    dataUriPolygonsSub3: "",
    storeLocation: "",
    suppliedImage: false,
    showPolygons: false,
    checked: false,
    imageDimensions: { width: 0, height: 0 },
    imageDimensions1: { width: 0, height: 0 },
    imageDimensions2: { width: 0, height: 0 },
    imageDimensions3: { width: 0, height: 0 },
  };

  componentDidMount() {
    this.setSreenshotState("load");
    const { channel, formData, serverIp, dataUri, dataUri1, dataUri2, dataUri3, storeLocation } = this.props;
    if (!dataUri) {
      this.setState({ storeLocation, suppliedImage: false }, () => {
        this.generateScreenshot(serverIp, channel, formData);
      });
    } else {
      this.setState(
        { dataUri1, dataUri2, dataUri3, dataUri, screenshotState: "success", storeLocation, suppliedImage: true },
        () => {
          this.updateImageDimensions(dataUri, dataUri1, dataUri2, dataUri3);
        }
      );
    }
  }

  async componentDidUpdate(prevProps) {
    const { channel, dataUri, dataUri1, dataUri2, dataUri3, formId, allChecked, updateSelectedCount } = this.props;
    const { checked } = this.state;
    if (prevProps.dataUri !== dataUri) {
      this.setState({ dataUri }, () => {
        this.updateImageDimensions(dataUri, dataUri1, dataUri2, dataUri3);
      });
    }
    if (prevProps.formId !== formId) {
      this.setSreenshotState("load");
      const { formData, serverIp } = this.props;
      if (!prevProps.dataUri) {
        this.setState({
          dataUriPolygons: "",
          dataUriPolygonsSub1: "",
          dataUriPolygonsSub2: "",
          dataUriPolygonsSub3: "",
        });
        await this.generateScreenshot(serverIp, channel, formData);
      } else {
        this.setState({ dataUri, dataUri1, dataUri2, dataUri3, screenshotState: "success" }, () => {
          this.updateImageDimensions(dataUri, dataUri1, dataUri2, dataUri3);
        });
      }
    }
    if (prevProps.allChecked !== allChecked && checked !== allChecked) {
      const { screenshot } = this.props;
      this.setState({ checked: allChecked });
      screenshot[2] = allChecked;
      updateSelectedCount(allChecked);
    }
  }

  handleCheckboxChange = () => {
    const { updateSelectedCount, screenshot } = this.props;
    const { checked } = this.state;

    this.setState({ checked: !checked });
    updateSelectedCount(!checked);

    screenshot[2] = !checked;
  };

  setSreenshotState(state) {
    this.setState({ screenshotState: state });
  }

  togglePolygons = () => {
    const { showPolygons } = this.state;
    this.setState({ showPolygons: !showPolygons });
  };

  async generateScreenshot(serverIp, channel, data) {
    const { storeLocation } = this.state;
    try {
      await triggerScreenshot(serverIp, channel, data)
        .then((response) => {
          const statePoint = response.data.state_point;
          return fetchScreenshot(serverIp, statePoint, data.video_recorder_id, channel, storeLocation);
        })
        .then((response) => {
          if (response.status === "success") {
            this.setState(
              {
                dataUri: response.data_uri,
                dataUri1: response.data_uri_1,
                dataUri2: response.data_uri_2,
                dataUri3: response.data_uri_3,
                dataUriPolygons: response.data_uri_polygons || "",
                dataUriPolygonsSub1: response.data_uri_sub1_polygons || "",
                dataUriPolygonsSub2: response.data_uri_sub2_polygons || "",
                dataUriPolygonsSub3: response.data_uri_sub3_polygons || "",
              },
              () => {
                this.updateImageDimensions(
                  response.data_uri,
                  response.data_uri_1,
                  response.data_uri_2,
                  response.data_uri_3
                );
              }
            );
            this.setSreenshotState("success");
          } else {
            this.setSreenshotState("fail");
          }
        });
    } catch (ex) {
      console.log(ex.response);
      if (ex.response && ex.response.status === 500) {
        if (ex.response.data) {
          toast.error(`Channel ${channel} : Error 500 ${ex.response.data}`);
        } else {
          toast.error(`Channel ${channel}: Error 500`);
        }
      } else if (ex.response && ex.response.status === 400) {
        Object.keys(ex.response.data).forEach(function (key) {
          const message = `${key}: ${ex.response.data[key][0]}`;
          toast.error(message);
        });
      } else if (ex.response && ex.response.status === 401) {
        toast.error(`Channel ${channel}: Error 401 ${ex.response.data}`);
      } else {
        toast.error(`Channel ${channel}: Unknown error`);
      }
      this.setSreenshotState("fail");
    }
  }

  updateImageDimensions(dataUri, dataUri1, dataUri2, dataUri3) {
    const img = new Image();
    const img1 = new Image();
    const img2 = new Image();
    const img3 = new Image();
    img.onload = () => {
      this.setState({ imageDimensions: { width: img.width, height: img.height } });
    };
    img.src = dataUri;
    img1.onload = () => {
      this.setState({ imageDimensions1: { width: img.width / 2, height: img.height / 2 } });
    };
    img1.src = dataUri1;
    img2.onload = () => {
      this.setState({ imageDimensions2: { width: img.width / 2, height: img.height / 2 } });
    };
    img2.src = dataUri2;
    img3.onload = () => {
      this.setState({ imageDimensions3: { width: img.width / 2, height: img.height / 2 } });
    };
    img3.src = dataUri3;
  }

  render() {
    const {
      screenshotState,
      showPolygons,
      suppliedImage,
      dataUri,
      dataUriPolygons,
      dataUriPolygonsSub1,
      dataUriPolygonsSub2,
      dataUriPolygonsSub3,
      checked,
      imageDimensions,
      imageDimensions1,
      imageDimensions2,
      imageDimensions3,
      dataUri1,
      dataUri2,
      dataUri3,
    } = this.state;
    const { channel, onClick, screenshot, checkbox, subChannels } = this.props;
    let { hasPolygons } = this.props;

    const getDataUriSrc = (regularData, polygonData) => {
      return !suppliedImage && polygonData && showPolygons ? polygonData : regularData;
    };
    const dataUriSrc = getDataUriSrc(dataUri, dataUriPolygons);
    const dataUri1Src = getDataUriSrc(dataUri1, dataUriPolygonsSub1);
    const dataUri2Src = getDataUriSrc(dataUri2, dataUriPolygonsSub2);
    const dataUri3Src = getDataUriSrc(dataUri3, dataUriPolygonsSub3);

    hasPolygons =
      !suppliedImage && (dataUriPolygons || dataUriPolygonsSub1 || dataUriPolygonsSub2 || dataUriPolygonsSub3);

    const channelInfo = (
      <>
        {` Channel ${channel}`}
        {hasPolygons && <i className="fas fa-draw-polygon text-primary ml-2" />}
      </>
    );

    const renderCardTitle = () => {
      if (checkbox && screenshot !== undefined) {
        return (
          <CardTitle tag="h5">
            <input
              type="checkbox"
              checked={checked}
              onChange={this.handleCheckboxChange}
              ref={(input) => {
                this.checkbox = input;
              }}
            />
            <span
              onClick={() => {
                this.checkbox.click();
              }}
              style={{ cursor: "pointer" }}
            >
              {channelInfo}
            </span>
          </CardTitle>
        );
      }
      return <CardTitle tag="h5">{channelInfo}</CardTitle>;
    };

    return (
      <div>
        <div>
          <center>{renderCardTitle()}</center>
        </div>
        <Card>
          <CardHeader />
          <CardBody>
            <center>
              {screenshotState === "success" && (
                <div style={{ display: "inline-block" }}>
                  <div style={{ display: "inline-block", marginRight: "10px" }}>
                    <img src={dataUriSrc} alt={channel} onClick={() => onClick(dataUriSrc)} width="200px" />
                    <p>
                      {imageDimensions.width} x {imageDimensions.height}
                    </p>
                  </div>

                  {/* Display the 3 dewarped images if subChannels.length > 0 */}
                  {subChannels && subChannels.length > 0 && (
                    <>
                      <div style={{ display: "inline-block", marginRight: "10px" }}>
                        <p>Channel {channel}-1</p>
                        <img src={dataUri1Src} alt={channel} onClick={() => onClick(dataUri1Src)} width="200px" />
                        <p>
                          {imageDimensions1.width} x {imageDimensions1.height}
                        </p>
                      </div>
                      <div style={{ display: "inline-block", marginRight: "10px" }}>
                        <p>Channel {channel}-2</p>
                        <img src={dataUri2Src} alt={channel} onClick={() => onClick(dataUri2Src)} width="200px" />
                        <p>
                          {imageDimensions2.width} x {imageDimensions2.height}
                        </p>
                      </div>
                      <div style={{ display: "inline-block", marginRight: "10px" }}>
                        <p>Channel {channel}-3</p>
                        <img src={dataUri3Src} alt={channel} onClick={() => onClick(dataUri3Src)} width="200px" />
                        <p>
                          {imageDimensions3.width} x {imageDimensions3.height}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              )}
              {screenshotState === "fail" && <Fail />}
              {screenshotState === "load" && <Load />}
            </center>
            {!suppliedImage && hasPolygons && (
              <Button className="ml-3" onClick={this.togglePolygons}>
                <i className="fas fa-draw-polygon text-primary mr-2" />
                {showPolygons ? "Hide polygons" : "Show polygons"}
              </Button>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Screenshot;
