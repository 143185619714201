import React from "react";
import { toast } from "react-toastify";

import { Row, Col, Badge } from "reactstrap";
import { restartServerContainer } from "../services/locationService";
import "./ServersServices.scss";

const ALLOWED_TO_RESTART_CONTAINERS = ["simone-inference", "replay_api"];
const ServerServices = ({ server, serverName }) => {
  const restarted = {};
  async function restartContainer(container) {
    if (restarted[container] === false) {
      try {
        const resp = await restartServerContainer(serverName, container);
        restarted[container] = true;
        toast.info(resp.data.detail);
      } catch (error) {
        if (error.response && error.response.status === 503) {
          toast.error(error.response.data.detail);
        }
      }
      await sleep(10000);
      restarted[container] = false;
    }
  }
  function RenderImage({ status }) {
    if (status === "OK") {
      return <i className="fas fa-check-circle text-success" />;
    }
    return <i className="fas fa-times-circle text-danger" />;
  }

  function ButtonRestart({ container }) {
    if (ALLOWED_TO_RESTART_CONTAINERS.includes(container)) {
      restarted[container] = false;
      return (
        <Badge className="btn-restart btn-primary btn" onClick={() => restartContainer(container)}>
          <i className="fas fa-power-off mr-2" /> Restart
        </Badge>
      );
    }
    return "";
  }
  const DateParsing = ({ datetime }) => {
    if (datetime === null) {
      return "Down";
    }
    const dateA = new Date(datetime);
    return dateA.toLocaleString("en-GB");
  };
  function ContainerParsing({ containerName }) {
    if (containerName === "simone-inference") {
      return <i className="fas fa-stroopwafel mr-2" />;
    }
    if (containerName === "replay_api") {
      return <i className="fas fa-play-circle mr-2" />;
    }
    if (containerName === "hd_upload") {
      return <i className="fas fa-cloud-upload-alt mr-2" />;
    }
    return "";
  }
  function BadgeVersion({ image }) {
    if (image === "" || typeof image === "undefined") {
      return <Badge>Unknown</Badge>;
    }
    return <Badge>{image.split(":")[1]}</Badge>;
  }
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  return Object.keys(server).map(
    (container) =>
      server[container].image && (
        <div className="servers-service">
          <Row>
            <Col md={3}>
              <ContainerParsing containerName={container} />
              {container}
            </Col>
            <Col md={3}>
              <BadgeVersion image={server[container].image} />
            </Col>
            <Col md={1}>
              <RenderImage status={server[container].status} />
            </Col>
            <Col md={2}>
              <DateParsing datetime={server[container].uptime} />
            </Col>
            <Col md={3}>
              <ButtonRestart container={container} />
            </Col>
          </Row>
        </div>
      )
  );
};
export default ServerServices;
