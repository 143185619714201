import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import InputControl from "../common/controls/Input";
import getArrayDepth from "../../utils/arrayUtils";
import PolygonForm from "./PolygonForm";
import { validateInteger } from "../../utils/validationUtils";
import { isResolution, polygonToRepresentation, textPolygonToInternalValue } from "../../utils/polygonUtils";
import { MINIMUM_RESOLUTION, MAXIMUM_RESOLUTION } from "../shared/constants";

export default function PolygonsTable({
  inputViewArea,
  inputOriginalresolution,
  onPolygonsUpdate,
  onOriginalResolutionSet,
  viewAreaUpdated,
}) {
  // backup data for cancel button
  const backupViewArea = inputViewArea ? [...inputViewArea] : [];
  const backupResolution = { ...inputOriginalresolution };

  const [viewArea, setViewArea] = useState(inputViewArea || []);
  const [modal, setModal] = useState(false);
  const [newPolygons, setNewPolygons] = useState([]);
  const [originalResolution, setOriginalResolution] = useState(inputOriginalresolution);
  const [createdResolution, setCreatedResolution] = useState({ width: null, height: null });
  const [errorX, setErrorX] = useState(null);
  const [errorY, setErrorY] = useState(null);
  const [hasErrors, setHasErrors] = useState(false);
  const [confirmReset, setConfirmReset] = useState(false);

  // viewArea should be a list of polygons. if only a list of points, add one more dimension

  useEffect(() => {
    setOriginalResolution(inputOriginalresolution);
  }, [inputOriginalresolution]);

  const viewAreaDepth = getArrayDepth(viewArea);
  if (viewAreaDepth === 2) {
    setViewArea([viewArea]);
  }

  useEffect(() => {
    setViewArea(inputViewArea || []);
  }, [inputViewArea]);

  const [textViewArea, setTextViewArea] = useState(viewArea.map((polygon) => polygonToRepresentation(polygon) || {}));

  useEffect(() => {
    if (Array.isArray(viewArea)) {
      setTextViewArea(viewArea.map((polygon) => polygonToRepresentation(polygon)));
    }
  }, [viewArea]);
  // 1 : [] empty list
  // 3 : [[[10,10],[50,40],[30,30]]] list of list (polygons) of list (coordinates)
  if (![1, 3].includes(viewAreaDepth)) {
    return <p>Cannot edit polygon points, bad data</p>;
  }

  function toggleModal() {
    setModal(!modal);
  }

  function textViewAreaToInternalValue(textViewAreaToFormat) {
    const viewAreaInternalValue = [];
    textViewAreaToFormat.forEach((textPolygon) => {
      viewAreaInternalValue.push(textPolygonToInternalValue(textPolygon));
    });
    return viewAreaInternalValue;
  }

  function handleChangeTextPolygon(index, textPolygon) {
    const updatedTextViewArea = textViewArea;
    updatedTextViewArea.splice(index, 1, textPolygon);
    setTextViewArea(updatedTextViewArea);
  }

  function handleChangeOriginalResolutionField({ currentTarget: input }) {
    const errorMessage = validateInteger(input.value, MINIMUM_RESOLUTION, MAXIMUM_RESOLUTION);
    if (input.name === "width") {
      setErrorX(errorMessage);
    } else if (input.name === "height") {
      setErrorY(errorMessage);
    }
    const updatedCreatedResolution = { ...createdResolution };
    updatedCreatedResolution[input.name] = parseInt(input.value) || input.value;
    setCreatedResolution(updatedCreatedResolution);
  }

  function handleSetHasErrors(childHasErrors) {
    setHasErrors(childHasErrors);
  }

  function toggleConfirmReset() {
    setConfirmReset(!confirmReset);
  }

  function handleResetResolution() {
    setOriginalResolution({ width: null, height: null });
    setTextViewArea([]);
    toggleConfirmReset();
  }

  function handleSetOriginalResolution() {
    const newErrorX = validateInteger(createdResolution.width, MINIMUM_RESOLUTION, MAXIMUM_RESOLUTION);
    const newErrorY = validateInteger(createdResolution.height, MINIMUM_RESOLUTION, MAXIMUM_RESOLUTION);
    setErrorX(newErrorX);
    setErrorY(newErrorY);
    if (newErrorX || newErrorY) return;
    setOriginalResolution(createdResolution);
    setTextViewArea([]);
  }

  function validate() {
    if (hasErrors || textViewArea.includes("")) {
      return;
    }
    onPolygonsUpdate(textViewAreaToInternalValue(textViewArea));
    onOriginalResolutionSet(originalResolution);
    setNewPolygons([]);
    toggleModal();
  }

  function cancel() {
    setTextViewArea(backupViewArea.map((polygon) => polygonToRepresentation(polygon)));
    setOriginalResolution(backupResolution);
    setCreatedResolution({ width: null, height: null });
    setConfirmReset(false);
    toggleModal();
  }

  function addPolygon() {
    const updatedNewPolygons = [...newPolygons];
    const updatedTextViewArea = [...textViewArea];
    updatedNewPolygons.push(textViewArea.length);
    updatedTextViewArea.push("");
    setNewPolygons(updatedNewPolygons);
    setTextViewArea(updatedTextViewArea);
  }

  function removePolygon(index) {
    const updatedTextViewArea = [...textViewArea];
    updatedTextViewArea.splice(index, 1);
    setTextViewArea(updatedTextViewArea);
  }

  return (
    <div className="float-left">
      <Button className="m-2" onClick={toggleModal}>
        <i className="fas fa-edit" /> Edit polygons
      </Button>
      {viewAreaUpdated && <p className="text-warning">Polygons updated, save the channel to preserve changes</p>}
      {modal && (
        <Modal isOpen={modal} toggle={toggleModal} contentClassName="image-modal bg-transparent">
          <ModalBody style={{ borderRadius: "10px", background: "#27293d", width: "700px" }}>
            <center>
              {textViewArea.length === 0 && (
                <div>
                  <p>No polygons</p>
                </div>
              )}
              {textViewArea &&
                textViewArea.length > 0 &&
                textViewArea.map((textPolygon, index) => (
                  <PolygonForm
                    key={textPolygon + index}
                    inputTextPolygon={textPolygon}
                    newPolygons={newPolygons}
                    originalResolution={originalResolution}
                    index={index}
                    onDeletePolygon={removePolygon}
                    onChangeTextPolygon={handleChangeTextPolygon}
                    onSetHasErrors={handleSetHasErrors}
                  />
                ))}
              {textViewArea.length > 0 && (
                <span className="float-left mt-2 mb-3" style={{ fontSize: 13 }}>
                  *editing a polygon will remove its anchor
                </span>
              )}
              <hr style={{ borderColor: "gray", marginTop: 15 }} />
              {isResolution(originalResolution) && !confirmReset && (
                <div className="d-flex justify-content-center w-100">
                  <p>
                    {`Original resolution : ${originalResolution.width.toString()}x${originalResolution.height.toString()}`}
                  </p>
                  <span className="spacer" style={{ margin: "5px" }} />
                  <Button
                    className="float-right w-30"
                    style={{ margin: "-5px   0 10px 0" }}
                    name="Set resolution"
                    onClick={toggleConfirmReset}
                  >
                    Reset
                  </Button>
                </div>
              )}
              {confirmReset && (
                <div className="d-flex w-100">
                  <span className="float-left text-danger w-50">
                    Are you sure you want to reset the original resolution ? This will <b>delete</b> all current
                    polygons
                  </span>
                  <Button className="float-left m-2 w-25" style={{ maxHeight: "38px" }} onClick={toggleConfirmReset}>
                    <i className="fas fa-times mr-2" /> Cancel
                  </Button>
                  <Button
                    className="m-2 btn-danger float-right w-25"
                    style={{ maxHeight: "38px" }}
                    onClick={handleResetResolution}
                  >
                    <i className="fas fa-trash-alt" /> Reset
                  </Button>
                </div>
              )}
              {!isResolution(originalResolution) && (
                <div>
                  <h4>Original resolution :</h4>
                  <div className="d-flex w-100">
                    <InputControl
                      name="width"
                      value={createdResolution.width}
                      onChange={handleChangeOriginalResolutionField}
                      error={errorX}
                    />
                    <span className="spacer" style={{ margin: "5px" }} />
                    <InputControl
                      name="height"
                      value={createdResolution.height}
                      onChange={handleChangeOriginalResolutionField}
                      error={errorY}
                    />
                    <span className="spacer" style={{ margin: "5px" }} />
                    <Button
                      className="float-right w-100"
                      style={{ margin: "0 0 10px 0", maxHeight: "38px" }}
                      name="Set resolution"
                      onClick={handleSetOriginalResolution}
                    >
                      Set resolution
                    </Button>
                  </div>
                  <div className="d-flex w-100">
                    <span className="float-left text-danger">
                      Reminder, setting a new original resolution will <b>delete</b> all current polygons
                    </span>
                  </div>
                </div>
              )}
              {isResolution(originalResolution) && (
                <Button className="m-2 float-left" onClick={addPolygon}>
                  <i className="fas fa-plus" /> Add polygon
                </Button>
              )}
              <Button className="m-2 float-right" name="cancel" onClick={cancel}>
                <i className="fas fa-times" /> Cancel
              </Button>
              <Button className="m-2 btn-success float-right" name="validate" onClick={validate}>
                <i className="fas fa-check" /> Validate
              </Button>
            </center>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
}
