import http from "./httpService";
import getArrayDepth from "../utils/arrayUtils";

const apiEndPoint = "/subchannels/";

function getUrl(id) {
  return `${apiEndPoint}${id}/`;
}

export function getSubChannels() {
  return http.get(apiEndPoint);
}

function cleanResolution(data, key) {
  const resolution = data[key];
  if (!resolution || !resolution.width || !resolution.height) {
    return null;
  }
  return { width: parseInt(resolution.width), height: parseInt(resolution.height) };
}

export function getSubChannel(subChannelId) {
  return http.get(getUrl(subChannelId));
}

function cleanPolygons(data, key) {
  let polygons = data[key];
  if (!polygons) {
    return [];
  }
  const polygonsDepth = getArrayDepth(polygons);
  if (polygonsDepth === 2) {
    polygons = [polygons];
  }
  polygons = polygons.map((polygon) => polygon.map(([x, y]) => [parseInt(x), parseInt(y)]));
  return polygons;
}

export function saveSubChannel(subChannel) {
  const body = { ...subChannel };
  Object.keys(body).forEach(function (key) {
    if (body[key] === "") delete body[key];
  });
  body.resolution = cleanResolution(body, "resolution");
  body.view_area = cleanPolygons(body, "view_area");
  body.favor_factor = parseFloat(body.favor_factor) === 0 ? null : body.favor_factor;
  if (body.id) {
    delete body.id;
    return http.patch(getUrl(subChannel.id), body);
  }
  return http.post(apiEndPoint, body);
}

export function deleteSubChannel(subChannelId) {
  return http.delete(getUrl(subChannelId));
}
