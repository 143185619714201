import React from "react";
import { Button, Col, Form, Row } from "reactstrap";
import BaseForm from "../../common/BaseForm";
import UpdatedvrModel from "../../models/remotecontrol/UpdatedvrModel";

class UpdatedvrForm extends BaseForm {
  state = {
    data: {},
    errors: {},
    useCompression: false,
    useFps: false,
    useResolution: false,
    useBitrate: false,
    useCbrVbr: false,
    useMotionDetection: false,
    useEncryption: false,
    useLowResolution: false,
    showOtherParameters: false,
    otherParametersButtonText: "Other Parameters",
  };

  type_dvrs = [
    { name: "HIK", id: "hik" },
    { name: "DAHUA", id: "dahua" },
    { name: "TVT", id: "tvt" },
    { name: "UNIVIEW", id: "uniview" },
    { name: "AXIS", id: "axis" },
  ];

  type_actions = [
    { name: "Display", id: "display" },
    { name: "Update", id: "update" },
    { name: "Reboot", id: "reboot" },
  ];

  flux_types = [
    { name: "MAIN", id: "all_main" },
    { name: "SUB", id: "all_sub" },
  ];

  compression_options = [
    { name: "H.264", id: "h264" },
    { name: "H.265", id: "h265" },
  ];

  fps_options = Array.from({ length: 25 }, (_, i) => ({ name: `${i + 1}`, id: `${i + 1}` }));

  resolution_options = [
    { name: "2560x1440", id: "2560x1440" },
    { name: "1920x1080 (Full HD)", id: "1920x1080" },
    { name: "1280x720 (HD)", id: "1280x720" },
    { name: "960x576 (960H)", id: "960x576" },
    { name: "960x480 (960H)", id: "960x480" },
    { name: "704x576 (D1)", id: "704x576" },
    { name: "704x480 (D1)", id: "704x480" },
    { name: "640x360", id: "640x360" },
    { name: "640x480", id: "640x480" },
    { name: "352x288 (CIF)", id: "352x288" },
  ];

  bitrate_options = [
    { name: "256 kbps", id: "256" },
    { name: "512 kbps", id: "512" },
    { name: "768 kbps", id: "768" },
    { name: "856 kbps", id: "856" },
    { name: "1024 kbps", id: "1024" },
    { name: "1536 kbps", id: "1536" },
    { name: "2048 kbps", id: "2048" },
    { name: "2560 kbps", id: "2560" },
    { name: "3072 kbps", id: "3072" },
  ];

  cbr_vbr_options = [
    { name: "CBR", id: "cbr" },
    { name: "VBR", id: "vbr" },
  ];

  true_false_options = [
    { name: "True", id: "true" },
    { name: "False", id: "false" },
  ];

  updatedvr = new UpdatedvrModel();
  schema = this.updatedvr.getSchema();

  componentDidMount() {
    const data = this.updatedvr.getDefaultData();
    const { videoRecorder } = this.props;

    if (videoRecorder) {
      if (videoRecorder.video_recorder_type_name !== "IP") {
        data.ip_dvr = videoRecorder.device_ip || "";
        data.username = videoRecorder.username || "";
        data.password = videoRecorder.password || "";
      } else {
        const channelNumbers = videoRecorder.channels.map((channel) => channel.channel_number);
        const ipRange = `{${channelNumbers.join(",")}}`;
        data.ip_dvr = videoRecorder.device_ip.replace("{}", ipRange) || "";
        data.username = videoRecorder.username || "";
        data.password = videoRecorder.password || "";
      }
    }

    this.setState({ data });
  }

  handleSubmit = async () => {
    await this.props.handleSubmit(this.state.data);
  };
  handleInstallClick = async () => {
    const { data } = this.state;
    data.type_action = "install"; // Assign the action "install" to the key "type_action" in the state
    await this.setState({ data }); // update the status
    // Submit the form directly after defining the "install" action
    await this.handleSubmit();
  };
  handleCheckboxChange = (name) => (e) => {
    this.setState({ [name]: e.target.checked }, () => {
      if (name === "useLowResolution" && this.state.useLowResolution) {
        this.applyLowResolutionSettings();
      } else if (name === "useLowResolution" && !this.state.useLowResolution) {
        this.resetLowResolutionSettings();
      }
    });
  };

  handleOtherParametersClick = () => {
    this.setState((prevState) => ({
      showOtherParameters: !prevState.showOtherParameters,
      otherParametersButtonText: prevState.showOtherParameters ? "Other Parameters" : "Hide the Parameters",
      useLowResolution: prevState.showOtherParameters ? prevState.useLowResolution : false,
    }));
  };

  applyLowResolutionSettings = () => {
    const { data } = this.state;

    data.fps = "6";
    data.bitrate = "512";
    data.compression = "H.264";

    switch (data.type_dvr) {
      case "dahua":
        data.resolution = "704x576";
        break;
      case "uniview":
        data.resolution = "720x576";
        break;
      default:
        break;
    }

    this.setState({ data, showOtherParameters: false });
  };

  resetLowResolutionSettings = () => {
    const { data } = this.state;

    delete data.fps;
    delete data.bitrate;
    delete data.compression;
    delete data.resolution;

    this.setState({ data });
  };

  render() {
    const {
      data,
      useLowResolution,
      useCompression,
      useFps,
      useResolution,
      useBitrate,
      useCbrVbr,
      useMotionDetection,
      useEncryption,
      showOtherParameters,
      otherParametersButtonText,
    } = this.state;

    let typeActionLabel = "";
    Object.keys(this.type_actions).forEach((key) => {
      if (this.type_actions[key].id === data.type_action) {
        typeActionLabel = this.type_actions[key].name;
      }
    });

    return (
      <Form>
        <Row>
          <Col md="12">{this.renderSelect("type_action", "Select Operation", this.type_actions)}</Col>
        </Row>
        <Row>
          <Col md="3">{this.renderSelect("type_dvr", "Dvr Type", this.type_dvrs)}</Col>
          <Col md="3">{this.renderInput("ip_dvr", "Dvr IP")}</Col>
          <Col md="3">{this.renderInput("username", "Dvr User")}</Col>
          <Col md="3">{this.renderInput("password", "Dvr Password")}</Col>
        </Row>
        {data.type_action === "update" && (
          <div>
            <Row>
              <Col md="3">{this.renderSelect("flux", "Flow", this.flux_types)}</Col>
              <Col md="3">
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      checked={useLowResolution}
                      onChange={this.handleCheckboxChange("useLowResolution")}
                    />{" "}
                    Low Resolution D1
                  </label>
                </div>
              </Col>
            </Row>

            {showOtherParameters && (
              <>
                <Row>
                  <Col md="3">
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={useCompression}
                          onChange={this.handleCheckboxChange("useCompression")}
                        />{" "}
                        Video Encoding
                      </label>
                      {useCompression && this.renderSelect("compression", "", this.compression_options)}
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="form-group">
                      <label>
                        <input type="checkbox" checked={useFps} onChange={this.handleCheckboxChange("useFps")} /> FPS
                      </label>
                      {useFps && this.renderSelect("fps", "", this.fps_options)}
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={useResolution}
                          onChange={this.handleCheckboxChange("useResolution")}
                        />{" "}
                        Resolution
                      </label>
                      {useResolution && this.renderSelect("resolution", "", this.resolution_options)}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={useBitrate}
                          onChange={this.handleCheckboxChange("useBitrate")}
                        />{" "}
                        Bitrate
                      </label>
                      {useBitrate && this.renderSelect("bitrate", "", this.bitrate_options)}
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="form-group">
                      <label>
                        <input type="checkbox" checked={useCbrVbr} onChange={this.handleCheckboxChange("useCbrVbr")} />{" "}
                        Type Bandwidth
                      </label>
                      {useCbrVbr && this.renderSelect("cbr_vbr", "", this.cbr_vbr_options)}
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={useMotionDetection}
                          onChange={this.handleCheckboxChange("useMotionDetection")}
                        />{" "}
                        Motion Detection
                      </label>
                      {useMotionDetection && this.renderSelect("motion_detection", "", this.true_false_options)}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={useEncryption}
                          onChange={this.handleCheckboxChange("useEncryption")}
                        />{" "}
                        Encryption (only HIK)
                      </label>
                      {useEncryption && this.renderSelect("encrypt", "", this.true_false_options)}
                    </div>
                  </Col>
                </Row>
              </>
            )}

            <Row>
              <Col md="12">
                <Button
                  className="btn-link text-decoration-none"
                  color="link"
                  onClick={this.handleOtherParametersClick}
                >
                  {otherParametersButtonText}
                </Button>
              </Col>
            </Row>
          </div>
        )}
        <Button className="float-left btn-info mt-3 mr-2" onClick={this.handleInstallClick}>
          Install Update-dvr
        </Button>
        <Button className="float-right btn-success m-2" onClick={this.handleSubmit}>
          <i className="fas fa-search mr-2" />
          {typeActionLabel}
        </Button>
      </Form>
    );
  }
}

export default UpdatedvrForm;
