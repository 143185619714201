import React from "react";
import { toast } from "react-toastify";

import * as Sentry from "@sentry/react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import LanguageRegionForm from "../components/Forms/LanguageRegionForm";
import NotifierForm from "../components/Forms/NotifierForm";
import StoreConfigForm from "../components/Forms/StoreConfigForm";
import VideoTuneForm from "../components/Forms/VideoTuneForm";
import ServerServicesList from "../components/ServersServices";
import DisplayWhen from "../components/common/base/DisplayWhen";
import IncidentList from "../components/incidents/IncidentList";
import OpeningHoursList from "../components/openinghours/OpeningHoursList";
import { adminEditorRole, csmMaintenanceRole, csmRole, editorRole, qualityRole } from "../components/shared/constants";
import userHasRole from "../components/shared/utils/authUtils";
import VideoRecorderList from "../components/videorecorders/VideoRecorderList";
import VtIpList from "../components/vtip/VtIpList";
import { returnServersContainersStatus } from "../services/locationService";
import { getStore } from "../services/storeService";

class StoreDetailView extends React.Component {
  state = {
    store: null,
    newStore: false,
    deleteMode: false,
    restartMode: false,
    servers: {},
    activeTabIndex: 0,
  };

  async componentDidMount() {
    const { match } = this.props;
    const storeId = match.params.id;
    if (storeId === "new") {
      const newStore = true;
      this.setState({ newStore });
      return;
    }
    await this.fetchStore(storeId);
  }

  async componentDidUpdate(prevProps) {
    const prevStoreId = prevProps.match.params.id;
    const { match } = this.props;
    const storeId = match.params.id;
    if (prevStoreId !== storeId) {
      await this.fetchStore(storeId);
    }
  }

  isCreationRole = () => userHasRole(editorRole) && !userHasRole(qualityRole);

  toggleDeleteMode = () => {
    const { deleteMode } = this.state;
    this.setState({ deleteMode: !deleteMode });
  };

  toggleRestartMode = () => {
    const { restartMode } = this.state;
    this.setState({ restartMode: !restartMode });
  };

  handleAddOrUpdateVideoTune = (videoTune) => {
    const { store } = this.state;

    store.video_tune = { ...videoTune };
    this.setState({ store });
  };

  handleAddOrUpdateNotifier = (notifiers) => {
    const { store } = this.state;
    store.notifiers = { ...notifiers };
    this.setState({ store });
  };

  toggleTab = (tabIndex) => {
    const activeTabIndex = this.state;
    if (activeTabIndex !== tabIndex) {
      this.setState({
        activeTabIndex: tabIndex,
      });
    }
  };

  fetchServersContainersStatus = async (location) => {
    try {
      const { data: serversStatus } = await returnServersContainersStatus(location);
      this.setState({ servers: serversStatus });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        if (ex.response.data && ex.response.data.detail) {
          toast.error(ex.response.data.detail);
        } else {
          toast.error("Failed to load machine services status");
        }
      } else if (ex.response && ex.response.status === 400) {
        Object.keys(ex.response.data).forEach(function (key) {
          const message = `${key}: ${ex.response.data[key][0]}`;
          toast.error(message);
        });
      } else if (ex.response && ex.response.status === 401) {
        toast.error(ex.response.data.error);
      } else {
        toast.error("Failed to load machine services status");
      }
    }
  };

  fetchStore = async (storeId) => {
    try {
      const { data: store } = await getStore(storeId);
      let analyzedStreams = 0;
      let analyzedFisheyes = 0;
      let unactiveStreams = 0;
      if (Object.prototype.hasOwnProperty.call(store, "video_recorders")) {
        store.video_recorders.forEach((videoRecorder) => {
          videoRecorder.channels.forEach((channel) => {
            if (!channel.is_active || !channel.inference_machine) {
              unactiveStreams += 1;
              return;
            }
            if (channel.sub_channels.length === 0) {
              analyzedStreams += 1;
            } else {
              analyzedFisheyes += 1;
            }
          });
        });
      }
      store.analyzed_streams = analyzedStreams;
      store.analyzed_fisheyes = analyzedFisheyes;
      store.unactive_streams = unactiveStreams;
      this.setState({ store });
      await this.fetchServersContainersStatus(store.location);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        if (ex.response.data && ex.response.data.detail) {
          toast.error(ex.response.data.detail);
        } else {
          toast.error("Store not found");
        }
      } else if (ex.response && ex.response.status === 400) {
        Object.keys(ex.response.data).forEach(function (key) {
          const message = `${key}: ${ex.response.data[key][0]}`;
          toast.error(message);
        });
      } else if (ex.response && ex.response.status === 401) {
        toast.error(ex.response.data.error);
      } else {
        toast.error("Unhandled error");
      }
    }
  };

  render() {
    const { store, newStore, deleteMode, restartMode, activeTabIndex, servers } = this.state;
    const shouldDisplayLanguageForm =
      store &&
      Object.prototype.hasOwnProperty.call(store, "country") &&
      Object.prototype.hasOwnProperty.call(store, "currency") &&
      Object.prototype.hasOwnProperty.call(store, "language");
    const shouldDisplayVideoTuneForm = store && Object.prototype.hasOwnProperty.call(store, "video_tune");
    const shouldDisplayVtIpForm = store && Object.prototype.hasOwnProperty.call(store, "vt_ips");
    const shouldDisplayNotifiersForm = store && Object.prototype.hasOwnProperty.call(store, "notifiers");
    const shouldDisplayVideoRecordersForm = store && Object.prototype.hasOwnProperty.call(store, "video_recorders");
    const shouldDisplayOpeningHoursForm = store && Object.prototype.hasOwnProperty.call(store, "opening_hours");
    const shouldDisplayIncidentsForm =
      store &&
      (userHasRole(csmRole) ||
        userHasRole(csmMaintenanceRole) ||
        userHasRole(qualityRole) ||
        userHasRole(adminEditorRole)) &&
      store.in_furious;
    let className = "store-config";
    if (deleteMode) className += " danger";
    if (restartMode) className += " primary";

    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card className={className}>
              <CardHeader className="mb-2">
                <h5 className="card-category">Store configuration</h5>
                <CardTitle tag="h2" className="flex justify-between">
                  <div className="flex">
                    <div className="">{store ? store.location : newStore ? "Creating new store" : "Loading"}</div>
                  </div>
                  <div>
                    {store && (
                      <Badge
                        color={store.analyzed_streams + store.analyzed_fisheyes === 0 ? "danger" : "dark"}
                        className="float-right mr-2"
                      >
                        <i className="fas fa-video mr-1" />
                        {store.analyzed_streams}
                        <i className="fas fa-eye mr-1 ml-2" />
                        {store.analyzed_fisheyes}
                        {store.analyzed_streams + store.analyzed_fisheyes > 1
                          ? " Analyzed Streams"
                          : " Analyzed Stream"}
                      </Badge>
                    )}
                    {store && (
                      <Badge color="dark" className="text-sm float-right mr-2">
                        <i className="fas fa-video-slash mr-2" />
                        {store.unactive_streams}
                      </Badge>
                    )}
                  </div>
                </CardTitle>
                <div className="flex">
                  {store && (
                    <div className="flex align-middle items-center gap-2 py-2">
                      <a className="" href={`/screenshots/${store.location}`}>
                        <Button>Screenshots</Button>
                      </a>
                      <a
                        className=""
                        href={`${process.env.REACT_APP_ALERTSBOT_URL}/telegram/store_selection/?location=${store.location}&telegram_group_id=${store.telegram_group_id}&manager_store_id=${store.id}`}
                      >
                        <Button>AlertsBot</Button>
                      </a>
                      <a
                        className="no-underline hover:no-underline"
                        href={`${process.env.REACT_APP_APPTUNE_URL}/stores/${store.location}`}
                      >
                        <button
                          type="button"
                          className="flex bg-neutral-900 hover:bg-neutral-800 font-semibold p-2 rounded-md items-center align-middle"
                        >
                          <img
                            src="https://staticfilesprod.blob.core.windows.net/common/apptune/apptune-logo-dark.png"
                            className="h-6"
                          />
                          <span className="text-base text-gray-100 px-2 ">AppTune</span>
                        </button>
                      </a>
                    </div>
                  )}
                </div>
              </CardHeader>
              <CardBody>
                {store && (
                  <React.Fragment>
                    <Nav tabs>
                      <NavItem role="button">
                        <NavLink
                          className={activeTabIndex === 0 ? "active" : ""}
                          onClick={() => {
                            this.toggleTab(0);
                          }}
                        >
                          <p>Store Config</p>
                        </NavLink>
                      </NavItem>
                      <DisplayWhen condition={shouldDisplayVideoRecordersForm}>
                        <NavItem role="button">
                          <NavLink
                            className={activeTabIndex === 1 ? "active" : ""}
                            onClick={() => {
                              this.toggleTab(1);
                            }}
                          >
                            <p>Video Recorders</p>
                          </NavLink>
                        </NavItem>
                      </DisplayWhen>
                      <DisplayWhen condition={shouldDisplayOpeningHoursForm}>
                        <NavItem role="button">
                          <NavLink
                            className={activeTabIndex === 2 ? "active" : ""}
                            onClick={() => {
                              this.toggleTab(2);
                            }}
                          >
                            <p>Opening Hours</p>
                          </NavLink>
                        </NavItem>
                      </DisplayWhen>
                      <DisplayWhen condition={shouldDisplayVideoTuneForm}>
                        <NavItem role="button">
                          <NavLink
                            className={activeTabIndex === 3 ? "active" : ""}
                            onClick={() => {
                              this.toggleTab(3);
                            }}
                          >
                            <p>Video Tune</p>
                          </NavLink>
                        </NavItem>
                      </DisplayWhen>
                      <DisplayWhen condition={shouldDisplayLanguageForm}>
                        <NavItem role="button">
                          <NavLink
                            className={activeTabIndex === 4 ? "active" : ""}
                            onClick={() => {
                              this.toggleTab(4);
                            }}
                          >
                            <p>Language & Region</p>
                          </NavLink>
                        </NavItem>
                      </DisplayWhen>
                      <DisplayWhen condition={shouldDisplayNotifiersForm}>
                        <NavItem role="button">
                          <NavLink
                            className={activeTabIndex === 5 ? "active" : ""}
                            onClick={() => {
                              this.toggleTab(5);
                            }}
                          >
                            <p>Alert Notifiers</p>
                          </NavLink>
                        </NavItem>
                      </DisplayWhen>
                      <DisplayWhen condition={shouldDisplayVtIpForm}>
                        <NavItem role="button">
                          <NavLink
                            className={activeTabIndex === 6 ? "active" : ""}
                            onClick={() => {
                              this.toggleTab(6);
                            }}
                          >
                            <p>Anaveo VT IPs</p>
                          </NavLink>
                        </NavItem>
                      </DisplayWhen>
                      <NavItem role="button">
                        {!servers.status && (
                          <NavLink
                            className={activeTabIndex === 7 ? "active" : ""}
                            onClick={() => {
                              this.toggleTab(7);
                            }}
                          >
                            <p>
                              <Spinner color="primary" size="sm" className="mr-1" />
                              Servers
                            </p>
                          </NavLink>
                        )}
                        {servers.status && (
                          <NavLink
                            className={activeTabIndex === 7 ? "active" : ""}
                            onClick={() => {
                              this.toggleTab(7);
                            }}
                          >
                            <p>Servers</p>
                          </NavLink>
                        )}
                      </NavItem>
                      <DisplayWhen condition={shouldDisplayIncidentsForm}>
                        <NavItem role="button">
                          <NavLink
                            className={activeTabIndex === 8 ? "active" : ""}
                            onClick={() => {
                              this.toggleTab(8);
                            }}
                          >
                            <p>Incidents</p>
                          </NavLink>
                        </NavItem>
                      </DisplayWhen>
                    </Nav>
                    <TabContent activeTab={activeTabIndex}>
                      <TabPane tabId={0}>
                        <StoreConfigForm
                          store={store}
                          newStore={false}
                          toggleDeleteMode={this.toggleDeleteMode}
                          toggleRestartMode={this.toggleRestartMode}
                          deleteMode={deleteMode}
                          restartMode={restartMode}
                          fetchStore={this.fetchStore}
                        />
                      </TabPane>

                      <DisplayWhen condition={shouldDisplayVideoRecordersForm}>
                        <TabPane tabId={1}>
                          <VideoRecorderList
                            videoRecorders={store.video_recorders}
                            storeId={store.id}
                            servers={servers}
                          />
                        </TabPane>
                      </DisplayWhen>

                      <DisplayWhen condition={shouldDisplayOpeningHoursForm}>
                        <TabPane tabId={2}>
                          <OpeningHoursList openingHours={store.opening_hours} storeId={store.id} />
                        </TabPane>
                      </DisplayWhen>

                      <DisplayWhen condition={shouldDisplayVideoTuneForm}>
                        <TabPane tabId={3}>
                          <VideoTuneForm
                            videoTune={store.video_tune}
                            store={store}
                            onSaveVideoTune={this.handleAddOrUpdateVideoTune}
                          />
                        </TabPane>
                      </DisplayWhen>

                      <DisplayWhen condition={shouldDisplayLanguageForm}>
                        <TabPane tabId={4}>
                          <LanguageRegionForm store={store} />
                        </TabPane>
                      </DisplayWhen>

                      <DisplayWhen condition={shouldDisplayNotifiersForm}>
                        <TabPane tabId={5}>
                          <NotifierForm
                            notifiers={store.notifiers}
                            store={store}
                            onSaveNotifier={this.handleAddOrUpdateNotifier}
                          />
                        </TabPane>
                      </DisplayWhen>

                      <DisplayWhen condition={shouldDisplayVtIpForm}>
                        <TabPane tabId={6}>
                          <VtIpList vtIps={store.vt_ips} storeId={store.id} store={store} />
                        </TabPane>
                      </DisplayWhen>

                      {!servers.status && (
                        <TabPane tabId={7}>
                          <Spinner color="primary" />
                        </TabPane>
                      )}
                      {servers.status && (
                        <TabPane tabId={7}>
                          <ServerServicesList servers={servers} store={store} />
                        </TabPane>
                      )}
                      <DisplayWhen condition={shouldDisplayIncidentsForm}>
                        <TabPane tabId={8}>
                          <IncidentList store={store} />
                        </TabPane>
                      </DisplayWhen>
                    </TabContent>
                  </React.Fragment>
                )}
                {!store && !newStore && <Spinner color="primary" />}
                <DisplayWhen condition={newStore && this.isCreationRole()}>
                  <StoreConfigForm newStore />
                </DisplayWhen>
              </CardBody>
              <CardFooter />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Sentry.withProfiler(StoreDetailView);
