import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { getSubChannel } from "../../services/subChannelService";

class ChannelScreenshot extends Component {
  state = {
    channel: null,
    videoRecorder: {},
    hasViewArea: false,
  };

  async componentDidMount() {
    const { channel, videoRecorder } = this.props;
    this.setState({ channel, videoRecorder });

    // Check for the presence of view_area in the channel and its sub_channels
    await this.checkViewAreaInChannel(channel);
  }

  checkViewAreaInChannel = async (channel) => {
    if (channel.view_area) {
      this.setState({ hasViewArea: true });
      return;
    }

    const viewAreaExists = await Promise.all(
      channel.sub_channels.map(async (subChannelId) => {
        try {
          const { data: subChannel } = await getSubChannel(subChannelId);
          return !!subChannel.view_area;
        } catch (error) {
          console.error(`Error retrieving subchannel ${subChannelId}`, error);
          return false;
        }
      })
    );

    // Update hasViewArea if one of the subchannels has a view_area
    if (viewAreaExists.includes(true)) {
      this.setState({ hasViewArea: true });
    }
  };

  handleClick = () => {
    const { channel, videoRecorder } = this.state;
    const { onClick } = this.props;
    const data = {
      video_recorder_id: videoRecorder.id,
    };
    let server = null;

    if (!channel.inference_machine_data) {
      toast.error("Can't generate screenshot for unassigned channels");
      return;
    }
    server = channel.inference_machine_data.hostname;
    onClick(channel.channel_number, data, server, channel.sub_channels);
  };

  render() {
    const { channel, videoRecorder, hasViewArea } = this.state;
    return (
      channel && (
        <tr className="channel">
          <td>
            {channel.channel_number} {hasViewArea && <i className="fas fa-draw-polygon text-primary mr-2" />}
          </td>
          <td>{channel.channel_name}</td>
          <td>
            <span className={`badge ${channel.protocol}`}>{channel.protocol}</span>
          </td>
          <td>{videoRecorder.video_recorder_type_name}</td>
          <td>{videoRecorder.device_ip}</td>
          <td>{videoRecorder.device_rtsp_port}</td>
          <td>{videoRecorder.sub_type}</td>
          <td>
            {channel.inference_machine_data ? (
              <Link to={`/inferencemachines/${channel.inference_machine_data.id}`}>
                <span className="machine">
                  <i className="fas fa-microchip mr-2" />
                  {channel.inference_machine_data.hostname}
                </span>
              </Link>
            ) : (
              "Not assigned"
            )}
          </td>
          <td>
            <Button className="float-center" onClick={this.handleClick}>
              Screenshot
            </Button>
          </td>
        </tr>
      )
    );
  }
}

export default ChannelScreenshot;
