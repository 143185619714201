import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, Input, FormGroup } from "reactstrap";

import "react-datetime/css/react-datetime.css";
import DateTime from "react-datetime";
import moment from "moment";

import saveMissedTheft from "../../services/missedTheftService";
import getOperatingModes from "../../services/operatingModeService";

export default function MissedTheftsTable({ channelId, inputMissedThefts, onMissedTheftsUpdate }) {
  const [modal, setModal] = useState(false);
  const [newDateTime, setNewDateTime] = useState("");
  const [missedThefts, setMissedThefts] = useState(sortMissedTheftsByDate(inputMissedThefts));
  const [newMissedThefts, setNewMissedThefts] = useState([]);
  const [invalidInputMessage, setInvalidInputMessage] = useState("");
  const [operatingModes, setOperatingModes] = useState([]);
  const [selectedMode, setSelectedMode] = useState("");
  const [selectedModeName, setSelectedModeName] = useState("");

  useEffect(() => {
    getOperatingModes()
      .then((response) => {
        setOperatingModes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching operating modes:", error);
      });
  }, []);

  const toggleModal = () => {
    setModal(!modal);
  };

  function dateFromDBValue(dateTime) {
    return dateTime.replace("T", " ").replace("Z", "");
  }

  function sortMissedTheftsByDate(unorderedMissedThets) {
    if (!unorderedMissedThets) {
      return null;
    }
    return unorderedMissedThets.sort(function (a, b) {
      if (a.date < b.date) {
        return 1;
      }
      if (a.date > b.date) {
        return -1;
      }
      return 0;
    });
  }

  const handleAddMissedTheft = () => {
    if (newDateTime !== "" && selectedMode !== "" && !Number.isNaN(selectedMode) && invalidInputMessage === "") {
      const missedTheft = {
        channel: channelId,
        date: newDateTime,
        operating_mode: selectedMode,
        operating_mode_name: selectedModeName,
        isNew: true,
      };
      setMissedThefts(sortMissedTheftsByDate([...missedThefts, missedTheft]));
      setNewMissedThefts([...newMissedThefts, missedTheft]);
    }
  };

  const handleDatetimeChange = (dateTime) => {
    let dateTimeString = "";
    setInvalidInputMessage("");
    if (typeof dateTime !== "string") {
      dateTimeString = dateTime.format("YYYY-MM-DD HH:mm:ss");
    } else {
      const validInput = moment(dateTime, "YYYY-MM-DD HH:mm:ss", true).isValid();
      if (!validInput) {
        setInvalidInputMessage("Invalid value, please follow format YYYY-MM-DD HH:mm:ss");
      }
    }
    setNewDateTime(dateTimeString);
  };

  const handleModeChange = (event) => {
    const modeId = parseInt(event.target.value, 10);
    const modeName = operatingModes.find((mode) => mode.id === modeId)?.name || "Unknown";
    setSelectedMode(modeId);
    setSelectedModeName(modeName);
  };

  const handleCancel = () => {
    setNewDateTime("");
    setSelectedMode("");
    setInvalidInputMessage("");
    setNewMissedThefts([]);
    setMissedThefts(missedThefts);
    toggleModal();
  };

  const handleSave = () => {
    newMissedThefts.forEach((newMissedTheft) => {
      delete newMissedTheft.isNew;
    });
    saveMissedTheft(newMissedThefts);
    setNewMissedThefts([]);
    onMissedTheftsUpdate(missedThefts);
    toggleModal();
  };

  return (
    <div className="float-left w-50">
      <Button
        id="display-missed-theft-details-modal"
        onClick={toggleModal}
        style={{ margin: "0 0 10px 10px", maxHeight: "38px" }}
      >
        <i className="fas fa-plus mr-2" />
        Details
      </Button>
      {modal && (
        <Modal isOpen={modal} toggle={toggleModal} contentClassName="image-modal bg-transparent">
          <ModalBody
            style={{
              borderRadius: "10px",
              background: "#27293d",
              width: "700px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <center>
              <div id="missed-thefts-list" style={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}>
                {missedThefts &&
                  missedThefts.map((missedTheft, index) => (
                    <p key={index} style={{ color: missedTheft.isNew ? "green" : "" }}>
                      {dateFromDBValue(missedTheft.date)} - {missedTheft.operating_mode_name || "Unknown"}
                    </p>
                  ))}
              </div>
              {invalidInputMessage && <div style={{ color: "#ff576c", fontSize: "12px" }}>{invalidInputMessage}</div>}
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                <FormGroup style={{ marginRight: "10px", marginBottom: "0px" }}>
                  <Input
                    type="select"
                    name="operating_mode"
                    value={selectedMode}
                    onChange={handleModeChange}
                    className="custom-select"
                  >
                    <option value="">Select Operating Mode</option>
                    {operatingModes.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <DateTime
                  onChange={handleDatetimeChange}
                  value={newDateTime}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm:ss"
                  input
                />
                <Button
                  id="add-missed-theft-button"
                  style={{ marginLeft: "10px", marginBottom: "2px" }}
                  onClick={handleAddMissedTheft}
                >
                  <i className="fas fa-plus mr-2" />
                  Add Missed Theft
                </Button>
                {newMissedThefts && newMissedThefts.length > 0 && (
                  <Button
                    id="save-missed-thefts"
                    style={{ marginLeft: "10px", marginBottom: "2px" }}
                    className="btn-success"
                    onClick={handleSave}
                    name="save"
                  >
                    <i className="fas fa-save mr-2" />
                    Save
                  </Button>
                )}
                <Button
                  id="cancel-missed-thefts"
                  style={{ marginLeft: "10px", marginBottom: "2px" }}
                  onClick={handleCancel}
                >
                  <i className="fas fa-times mr-2" />
                  Cancel
                </Button>
              </div>
            </center>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
}
