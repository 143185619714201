import http from "./httpService";

const getResellerInfo = (machineId) => {
  return http.get(`/machine/reseller/${machineId}/`).then((response) => response.data);
};

const updateResellerInfo = (machineId, resellerId) => {
  const data = { reseller: resellerId };
  return http.put(`/machine/reseller/${machineId}/`, data).then((response) => response.data);
};

const getResellers = () => {
  return http.get("/resellers/").then((response) => response.data);
};

const createCloudInstance = () => {
  return http.post("/machine/cloud/aws/").then((response) => response);
};

export { getResellerInfo, updateResellerInfo, getResellers, createCloudInstance };
